/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
import Vue from 'vue'
let ngoUserNotesApiUrl = root.ngoUserNotesApiUrl.link;


export default {
  state: {
    getNGOUserNotesListState:{},
    getSGUserNotesListState:{}
  },
  mutations: {
    getNGOUserNotesListMutation(state, value) {
      Vue.set(state, 'getNGOUserNotesListState', value);
    },
    getSGUserNotesListMutation(state, value) {
      Vue.set(state, 'getSGUserNotesListState', value);
    },
  },
  actions: {
    // Get NGO User Notes List Action Function
    getSGUserNotesListFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoUserNotesApiUrl + '?support_group=' + payload.id).then(response => {
            context.commit('getSGUserNotesListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get NGO User Notes List Action Function
    getNGOUserNotesListFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoUserNotesApiUrl + '?ngo_form=' + payload.id).then(response => {
            context.commit('getNGOUserNotesListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    createNewNotesDetailsFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(ngoUserNotesApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Update Notes Details Api
    updateNewNotesDetailsFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(ngoUserNotesApiUrl + payload.data.id + '/', payload.data.data).then(res => {
              resolve(res);
            }).catch(err => {
              reject(err);
            })
        })
    },
    // Get Notes Details By Id Api
    getNotesDetailsByIdFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoUserNotesApiUrl + payload.id + '/', payload).then(res => {
              resolve(res);
            }).catch(err => {
              reject(err);
            })
        })
    },
    // Remove Notes Details Api
    deleteNewNotesDetailsFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(ngoUserNotesApiUrl + payload.id + '/').then(res => {
              resolve(res);
            }).catch(err => {
              reject(err);
            })
        })
    },
  },
  getters: {
    getNGOUserNotesListGetters : state => state.getNGOUserNotesListState,
    getSGUserNotesListGetters : state => state.getSGUserNotesListState,
  }
}