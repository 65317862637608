/*eslint-disable */
let BaseURL = process.env.VUE_APP_API_BASE_URL;
let BaseURLLymphomap = process.env.VUE_APP_DOMAIN_URL;
let root = {
authLoginApiUrl: {
  "link": BaseURL + "api/account/user/login/"
},
authSignupApiUrl: {
  "link": BaseURL + "api/account/user/sign-up/"
},
authForgotPwApiUrl: {
  "link": BaseURL + "api/account/user/otp-send/"
},
authForgotPwVerifyApiUrl: {
  "link": BaseURL + "pi/account/user/otp-verify/"
},
appointmentApiUrl: {
  "link": BaseURL + "api/account/user/appointment/"
},
selfUserInfoApiUrl: {
  "link": BaseURL + "api/account/user/user-detail/"
},
patientInfoApiUrl: {
  "link": BaseURL + "api/account/user/update-patient-data/"
},
recentAppointmentApiUrl: {
  "link": BaseURL + "api/account/user/appointment-filter/"
},
medicalRecordsApiUrl: {
  "link": BaseURL + "api/account/user/medical-form/"
},
diseasesListApiUrl: {
  "link": BaseURL + "api/account/user/diseases-list/"
},
profileAvatarApiUrl:{
  "link": BaseURL + "api/account/user/avatar/"
},
// Patient Chat room Create Api Url
patientWithChatRoomCreatedApiUrl: {
    "link": BaseURLLymphomap + "api/chat_app/patient_room_create/"
},
// Patient Chat room Detail Api Url
patientWithChatRoomDetailApiUrl: {
    "link": BaseURL + "api/chat/chatrooms-message/"
},
remarksApiUrl:{
  "link": BaseURL + "api/account/user/doctor-remark/"
},
OtpSendOnMobileApiUrl:{
  "link": BaseURL + "api/account/user/mobile-otp/"
},
MobileOtpVerifyApiUrl:{
  "link": BaseURL + "api/account/user/mobile-verify/"
},
signUpVerifyApiUrl:{
  "link": BaseURL + "api/account/user/signup-verify/"
},
categoryListOfProductApiUrl:{
  "link": BaseURL + "api/account/consult/doctor/categories/"
},
categoryDetailOfProductApiUrl:{
  "link": BaseURL + "api/account/user/detail-category-doctor/"
},
prescriptionListApiUrl:{
  "link": BaseURL + "api/account/consult/get_prescription/"
},
publicPrescriptionApiUrl:{
  "link": "https://demo-api.lymphomap.ai/api/account/user/public-prescription-pdf/"
},
// Filters List For SignUp Form 
memberFiltersForSignUpApiUrl:{
  "link" : "https://demo-api.lymphomap.ai/api/account/user/member-category/"
},
searchDoctorApiUrl:{
  "link": BaseURL + "api/account/user/search-doctor/"
},
idProofApiUrl:{
  "link": BaseURL + "api/account/user/id-proof/"
},
requestSignupToLymphomapApiUrl:{
  "link": BaseURL + "api/account/user/lymphomap-services-sign-up/"
},
lbilLinkageToLymphomapInfoApiUrl:{
  "link": BaseURL + "api/account/user/check-lymphomap-profile/"
},
favouriteAppointmentListApiUrl:{
"link": BaseURL + "api/account/user/favourite-appointment/"
},
generateChatRoomId:{
  "link": BaseURL + "api/account/user/room-create/"
},
ngoApiUrl:{
  "link": BaseURL + "api/account/user/ngo-form/"
},
getMedicalRecordListApiUrl:{
  "link": BaseURL + "api/account/user/medical-records/"
},
userSupportHelpApiUrl:{
  "link": BaseURL + "api/account/user/patient-help-chat/detail/"
},
publicNgoListApiUrl:{
  "link": BaseURL + "api/account/user/public-ngo/"
},
supportGroupApiUrl:{
  "link": BaseURL + "api/account/user/support-group/"
},
allSupportGroupListApiUrl:{
  "link": BaseURL + "api/account/user/all-support-group/"
},
updateNgoMemberApiUrl:{
  "link": BaseURL + "api/account/user/update-ngo-member/"
},
updateSGMemberApiUrl:{
  "link": BaseURL + "api/account/user/update-support-group-member/"
},
doctorPresListApiUrl:{
  "link": BaseURL + "api/account/consult/get_all_prescription/"
},
loginUrl:{
  "link": BaseURL + "api/account/user/login/"
},
loginOtpUrl:{
  "link": BaseURL + "api/account/user/mobile-verify/"
},
doctorInitialMessageApiUrl:{
  "link": BaseURL + "api/account/user/initial-doctor-message/"
},
patientGroupChatRoomCreateApiUrl:{
  "link": BaseURL + "api/account/user/patient-room-create/"
},
NgoGroupList:{
  "link": BaseURL + "api/account/user/lbil-ngo-group-chat/list/"
},
ngoGroupInfoApiUrl:{
  "link": BaseURL + "api/account/user/ngo-group-chat/detail/"
},
ngoFullChatListApiUrl:{
  "link": BaseURL + "api/account/user/lbil-full-chat/list/"
},
removeNGoInfoApiUrl:{
  "link": BaseURL + "api/account/user/lbil-delete-group/"
},
ngoGroupRoomMeetingApiUrl:{
  "link": BaseURL + "api/account/user/group-video-room/"
},
ngoMemberChatRoomListApiUrl:{
  "link": BaseURL + "api/account/user/lbil-chat/list/"
},
ngoMemberChatRoomInfoApiUrl:{
  "link": BaseURL + "api/account/user/lbil-chat/detail/"
},
ngoUserNotesApiUrl:{
  "link": BaseURL + "api/account/user/user-notes/"
},
favouriteDoctorApiUrl:{
  "link": BaseURL + "api/account/user/favourite-doctor/"
},
favouriteDoctorListApiUrl:{
  "link": BaseURL + "api/account/user/favourite-doctor-list/"
},
groupDpPhotoUploadApiUrl:{
  "link": BaseURLLymphomap + "api/chat_app/lbil-group/chat/detail/"
},
roomCreateIdforGlobalSocketApi:{
  "link": BaseURL + "api/account/user/generate-user-common-room/"
},
postNotificationApiUrl:{
  "link": BaseURL + "api/account/user/add-notification/"
},
uploadMulitpleFileOfHrApiUrl:{
  "link": BaseURL + "api/account/user/medical-add-multiple-files/"
},
getMulitpleFileOfHrApiUrl:{
  "link": BaseURL + "api/account/user/all-get-medical-multiple-files/"
},
paymentStatusForApiUrl:{
  "link": BaseURL + "api/account/user/payment_appointment/"
},
memberListOfNGOApiUrl:{
  "link": BaseURL + "api/account/user/members-ngo-form/"
},
lastMessageReadApiUrl:{
  "link": BaseURL + "api/account/user/update_read_status/"
},
uploadMultipleFilesApiUrl:{
  "link": BaseURL + "api/account/user/update-files-data/"
},
unapprovedUserApiUrl:{
  "link": BaseURL + "api/account/user/unapproved-ngo-form/"
},
doctorConsultFeesApiUrl:{
  "link": BaseURL + "api/account/payments/doctor-consulting-fees/"
},
getPaymentHistoryApiUrl:{
  "link": BaseURL + "api/account/payments/get-patient-transaction-history/"
},
getAllPublicNgoListApiUrl:{
  "link": BaseURL + "api/account/user/all-ngo-list/"
},
getAllPaginatedPublicNgoListApiUrl:{
  "link": BaseURL + "api/account/user/paginated-ngo-list/"
},
getAllPublicNgoInfoApiUrl:{
  "link": BaseURL + "api/account/user/ngo-details/"
},
getAppointmentIdApiUrl:{
  "link": BaseURL + "api/account/payments/get-appointment-id/"
},
doctorFeedbackApiUrl:{
  "link": BaseURL + "api/account/consult/review/"
},
consultDoctorsApiUrl: {
  "link": BaseURL + "api/account/consult/doctors/"
},
doctorsReviewListByIdApiUrl: {
  "link": BaseURL + "api/account/consult/patient/reviews/"
},
}
export default root;