/*eslint-disable */
import {bus} from '@/main';
export default {
  name: 'before-login-header',
  components: {},
  props: [],
  data () {
    return {
      showBtn:true
    }
  },
  computed: {

  },
  mounted () {
    bus.$on('showHeaderSection', (data) => {
      this.showBtn = data;
    });

  },
  methods: {
  },
}


