/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let ngoApiUrl = root.ngoApiUrl.link;
let publicNgoListApiUrl = root.publicNgoListApiUrl.link;
let supportGroupApiUrl = root.supportGroupApiUrl.link;
let allSupportGroupListApiUrl = root.allSupportGroupListApiUrl.link;
let updateNgoMemberApiUrl = root.updateNgoMemberApiUrl.link;
let updateSGMemberApiUrl = root.updateSGMemberApiUrl.link;
let patientGroupChatRoomCreateApiUrl = root.patientGroupChatRoomCreateApiUrl.link;
let NgoGroupList = root.NgoGroupList.link;
let ngoGroupInfoApiUrl = root.ngoGroupInfoApiUrl.link;
let ngoFullChatListApiUrl = root.ngoFullChatListApiUrl.link;
let removeNGoInfoApiUrl = root.removeNGoInfoApiUrl.link;
let ngoGroupRoomMeetingApiUrl = root.ngoGroupRoomMeetingApiUrl.link;
let ngoMemberChatRoomListApiUrl = root.ngoMemberChatRoomListApiUrl.link;
let ngoMemberChatRoomInfoApiUrl = root.ngoMemberChatRoomInfoApiUrl.link;
let groupDpPhotoUploadApiUrl = root.groupDpPhotoUploadApiUrl.link;
let memberListOfNGOApiUrl = root.memberListOfNGOApiUrl.link;
let unapprovedUserApiUrl = root.unapprovedUserApiUrl.link;
let getAllPublicNgoListApiUrl = root.getAllPublicNgoListApiUrl.link;
let getAllPaginatedPublicNgoListApiUrl = root.getAllPaginatedPublicNgoListApiUrl.link;

export default {
  state: {
    ngoListState:{},
    publicNgoListState:{},
    supportGroupListState:{},
    allSupportGroupListState:{},
    getNgoInfoState:{},
    getSGInfoState:{},
    getNgoGroupListState:{},
    getNgoGroupInfoByIdState:{},
    getNgoFullChatListState:{},
    getMeetingListOfNgoState:{},
    getNgoMemberChatRoomListState:{},
    getMeetingListOfSupportGroupState:{},
    getAllPublicNgoListState:{}
  },
  mutations: {
    ngoListMutation(state, value) {
      Vue.set(state, 'ngoListState', value);
    },
    publicNgoListMutation(state, value) {
      Vue.set(state, 'publicNgoListState', value);
    },
    supportGroupListMutation(state, value) {
      Vue.set(state, 'supportGroupListState', value);
    },
    allSupportGroupListMutation(state, value) {
      Vue.set(state, 'allSupportGroupListState', value);
    },
    getNgoInfoMutation(state, value) {
      Vue.set(state, 'getNgoInfoState', value);
    },
    getSGInfoMutation(state, value) {
      Vue.set(state, 'getSGInfoState', value);
    },
    getNgoGroupListMutation(state, value) {
      Vue.set(state, 'getNgoGroupListState', value);
    },
    getNgoGroupInfoByIdMutation(state, value) {
      Vue.set(state, 'getNgoGroupInfoByIdState', value);
    },
    getNgoFullChatListMutation(state, value) {
      Vue.set(state, 'getNgoFullChatListState', value);
    },
    getMeetingListOfNgoMutation(state, value) {
      Vue.set(state, 'getMeetingListOfNgoState', value);
    },
    getMeetingListOfSupportGroupMutation(state, value) {
      Vue.set(state, 'getMeetingListOfSupportGroupState', value);
    },
    getNgoMemberChatRoomListMutation(state, value) {
      Vue.set(state, 'getNgoMemberChatRoomListState', value);
    },
    getNgoMemberChatRoomInfoByIdMutation(state, value) {
      Vue.set(state, 'getNgoMemberChatRoomInfoByIdState', value);
    },
    getAllPublicNgoListMutation(state, value) {
      Vue.set(state, 'getAllPublicNgoListState', value);
    }
  },
  actions: {
    // Get All Paginated Public Ngo List Function 
    getAllPaginatedPublicNgoListFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(getAllPaginatedPublicNgoListApiUrl + "?page_size=3").then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get All Public Ngo List Function
    getAllPublicNgoListFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(getAllPublicNgoListApiUrl).then(response => {
            context.commit('getAllPublicNgoListMutation', response);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Unapproved User Of Workspace 
   getUnapprovedUserOfWorkspaceByIdFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(unapprovedUserApiUrl + payload.id + '/').then(response => {
            // context.commit('getNgoMemberChatRoomInfoByIdMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Member List Of Ngo Method
   getMemberListOfNgoByIdFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(memberListOfNGOApiUrl + payload.id + '/').then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // group Dp Photo Upload Method
    groupDpPhotoUploadFunc(context, payload) {
        return new Promise((resolve, reject) => {
          var instance = axios.create();
          delete instance.defaults.headers.common['Authorization'];
          instance.patch(groupDpPhotoUploadApiUrl + payload.data.id + '/', payload.data.data).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // NGo Group Room Meeting Get Method
   getNgoMemberChatRoomInfoByIdFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoMemberChatRoomInfoApiUrl + payload.id + '/').then(response => {
            context.commit('getNgoMemberChatRoomInfoByIdMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // NGo Group Room Meeting Get Method
   getNgoMemberChatRoomListFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoMemberChatRoomListApiUrl + '?ngo_id=' + payload.id).then(response => {
            context.commit('getNgoMemberChatRoomListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // NGo Group Room Meeting Get Method
   getMeetingListOfSupportGroupFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoGroupRoomMeetingApiUrl + '?support_group=' + payload.id + '&is_public=true').then(response => {
            context.commit('getMeetingListOfSupportGroupMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // NGo Group Room Meeting Get Method
   getMeetingListOfNgoFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoGroupRoomMeetingApiUrl + '?ngo_id=' + payload.id + '&is_public=true').then(response => {
            context.commit('getMeetingListOfNgoMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // NGo Group Room Meeting Create Method
   createMeetingOfNgoFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(ngoGroupRoomMeetingApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Remove Ngo Info By Id Method
   removeNgoInfoByIdFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(removeNGoInfoApiUrl + payload.id + '/').then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get NGO Full Chat List Method
   getNgoFullChatListFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoFullChatListApiUrl).then(response => {
              context.commit('getNgoFullChatListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get NGO group Info Method
   getNgoGroupInfoByIdFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoGroupInfoApiUrl + payload.id + '/').then(response => {
              context.commit('getNgoGroupInfoByIdMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // create Group Ngo Member Action Function
   getGroupListOfNGOFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(NgoGroupList + '?ngo_id=' + payload.id).then(response => {
              context.commit('getNgoGroupListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // create Group Ngo Member Action Function
   createGroupOfNGOMemberFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(patientGroupChatRoomCreateApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get SG Info By ID Action Function
    getSGInfoNByIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(supportGroupApiUrl + payload.id + '/').then(response => {
              context.commit('getSGInfoMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get NGO Info By ID Action Function
    getNGOInfoNByIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoApiUrl + payload.id + '/').then(response => {
              context.commit('getNgoInfoMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // update Ngo Member Action Function
    updateSGMemberFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(updateSGMemberApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // update Ngo Member Action Function
    updateNgoMemberFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(updateNgoMemberApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Support List Action Function
    getAllSupportGroupListFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(allSupportGroupListApiUrl, payload).then(response => {
            context.commit('allSupportGroupListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Support List Action Function
    getSupportGroupListFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(supportGroupApiUrl, payload).then(response => {
            context.commit('supportGroupListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // New Support Register With Info Function
    registerSupportGroupFun(context,payload){
      return new Promise ((resolve, reject) => {
         axios.post(supportGroupApiUrl, payload).then((res)=>{
          resolve(res);
         }).catch((err)=>{
          reject(err);
         })
      })
    },
    // Get Public NGO List Action Function
    getPublicNgoListFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(publicNgoListApiUrl, payload).then(response => {
            context.commit('publicNgoListMutation', response);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get NGO List Action Function
    getNGOApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(ngoApiUrl, payload).then(response => {
            context.commit('ngoListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // New Ngo Register With Info Function
    registerNgoInfoFun(context,payload){
      return new Promise ((resolve, reject) => {
         axios.post(ngoApiUrl, payload).then((res)=>{
          resolve(res);
         }).catch((err)=>{
          reject(err);
         })
      })
    },
  },
  getters: {
    ngoListGetters : state => state.ngoListState,
    publicNgoListGetters : state => state.publicNgoListState,
    supportGroupListGetters : state => state.supportGroupListState,
    allSupportGroupListGetters : state => state.allSupportGroupListState,
    getNgoInfoGetters : state => state.getNgoInfoState,
    getSGInfoGetters : state => state.getSGInfoState,
    getNgoGroupListGetters : state => state.getNgoGroupListState,
    getNgoGroupInfoByIdGetters : state => state.getNgoGroupInfoByIdState,
    getNgoFullChatListGetters : state => state.getNgoFullChatListState,
    getMeetingListOfNgoGetters : state => state.getMeetingListOfNgoState,
    getNgoMemberChatRoomListGetters : state => state.getNgoMemberChatRoomListState,
    getNgoMemberChatRoomInfoByIdGetters : state => state.getNgoMemberChatRoomInfoByIdState,
    getMeetingListOfSupportGroupGetter : state => state.getMeetingListOfSupportGroupState,
    getAllPublicNgoListGetters : state => state.getAllPublicNgoListState
  }
}