/*eslint-disable */

import Vue from 'vue';
import Vuex from 'vuex';
import appointmentStore from './appointmentStore';
import authStore from './authStore';
import doctorChatStore from './doctorChatStore';
import doctorFeedbackStore from './doctorFeedbackStore.js';
import doctorStore from './doctorStore';
import healthRecordStore from './healthRecordStore';
import ngoNotesStore from './ngoNotesStore.js';
import ngoStore from './ngoStore.js';
import notificationStore from './notificationStore.js';
import onlineTherapyStore from './onlineTherapyStore.js';
import paymentStore from './paymentStore.js';
import publicStore from './publicStore';
import registerStore from './registerStore';
import userInfoStore from './userInfoStore';

export const strict = false
Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    authStore,
    userInfoStore,
    appointmentStore,
    doctorStore,
    healthRecordStore,
    doctorChatStore,
    registerStore,
    publicStore,
    ngoStore,
    ngoNotesStore,
    notificationStore,
    onlineTherapyStore,
    paymentStore,
    doctorFeedbackStore
  }
})