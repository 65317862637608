/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
import Vue from 'vue'
let OtpSendOnMobileApiUrl = root.OtpSendOnMobileApiUrl.link;
// let MobileOtpVerifyApiUrl = root.MobileOtpVerifyApiUrl.link;
let requestSignupToLymphomapApiUrl = root.requestSignupToLymphomapApiUrl.link;

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    // Send Otp On Mobile Function
    optCreateOnMobileApiFun(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(OtpSendOnMobileApiUrl, payload).then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
    // Otp Verify On Mobile Function
    // optVerifyOnMobileApiFun(context, payload) {
    //     return new Promise((resolve, reject) => {
    //       axios.post(MobileOtpVerifyApiUrl, payload).then(response => {
    //         resolve(response);
    //         context.commit('authSuccess', response.data.data.token);
    //         localStorage.setItem('LoginedUserID', response.data.data.user.id);
    //         localStorage.setItem('token', response.data.data.token);
    //         axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
    //         axios.defaults.headers.post['Content-Type'] = 'application/json';
    //         resolve(response);
    //       }).catch(err => {
    //         reject(err);
    //       })
    //     })
    // },
    // Request Signup To Lymphomap Function
    requestSignupToLymphomapFun(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(requestSignupToLymphomapApiUrl, payload).then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
  },
  getters: { 

  }
}