/*eslint-disable */

import axios from 'axios';
import root from '@/_helpers/roots';

let authLoginApiUrl = root.authLoginApiUrl.link;
let authSignupApiUrl = root.authSignupApiUrl.link;
let authForgotPwApiUrl = root.authForgotPwApiUrl.link;
let authForgotPwVerifyApiUrl = root.authForgotPwVerifyApiUrl.link;
let loginUrl = root.loginUrl.link;
let loginOtpUrl = root.loginOtpUrl.link;
let signUpVerifyApiUrl = root.signUpVerifyApiUrl.link;
export default {
  state: {
    token: localStorage.getItem('token') || '',
    status: '',
  },
  mutations: {
    authSuccess(state, token) {
      state.token = token;
      state.status = 'success';
    },
    authError(state) {
      state.token = '';
      state.status = 'error';
    },
    authLogout(state) {
      state.token = '';
      state.status = '';
    },
  },
  actions: {
    // Signup Verify Api Action Function
    signUpVerifyAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(signUpVerifyApiUrl, payload).then(response => {
              resolve(response);
              context.commit('authSuccess', response.data.data.token);
              localStorage.setItem('LoginedUserInfo', JSON.stringify(response.data.data.user));
              localStorage.setItem('LoginedUserID', response.data.data.user.id);
              localStorage.setItem('token', response.data.data.token);
              axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
              axios.defaults.headers.post['Content-Type'] = 'application/json';
              resolve(response);
            }).catch(err => {
                reject(err);
            })
        })
    },
    checkLoginType(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(loginUrl, payload).then(
            (response) => {
              resolve(response);
            }
          )
          .catch((error) => {
            reject(error);
          })
      })
    },
    // Otp Verify On Mobile Function
    optVerifyOnMobileApiFun(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(loginOtpUrl, payload).then(response => {
            resolve(response);
            context.commit('authSuccess', response.data.data.token);
            localStorage.setItem('LoginedUserInfo', JSON.stringify(response.data.data.user));
            localStorage.setItem('LoginedUserID', response.data.data.user.id);
            localStorage.setItem('token', response.data.data.token);
            axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
    loginWithOtp(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(loginOtpUrl, payload)
          .then((response) => {
            context.commit('authSuccess', response.data.data.token);
            localStorage.setItem('LoginedUserID', response.data.data.user.id);
            localStorage.setItem('LoginedUserInfo', JSON.stringify(response.data.data.user));
            localStorage.setItem('token', response.data.data.token);
            axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            context.commit('authError');
            reject(error);
          })
      })
    },
    // Login Api Action Function
    authUserLoginApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(authLoginApiUrl, payload).then(response => {
            context.commit('authSuccess', response.data.data.token);
            localStorage.setItem('LoginedUserID', response.data.data.user.id);
            localStorage.setItem('LoginedUserInfo', JSON.stringify(response.data.data.user));
            localStorage.setItem('token', response.data.data.token);
            axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            resolve(response);
            }).catch(err => {
            localStorage.removeItem('token');
            context.commit('authError');
            reject(err);
            })
        })
    },
    // Signup Api Action Function
    authUserSignupApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(authSignupApiUrl, payload).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },
    // Logout Action Function
    logout(context) {
      return new Promise((resolve, reject) => {
        context.commit('authLogout');
        localStorage.removeItem('token');
        localStorage.removeItem('LoginedUserID');
        localStorage.removeItem('LoginedUserInfo');
        localStorage.removeItem('userAgreementState');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      })
    },
    // Token Header Set Action Function
    getAuthHeader() {
      return {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    },
    // Forgot Password Action Function
    authUserForgotPwApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(authForgotPwApiUrl, payload).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },
    // Forgot Password Vrify Action Function
    authUserForgotPwVerifyApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(authForgotPwVerifyApiUrl, payload).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },
  },
  getters: {
    isAuthenticated: state => !!state.token && !!localStorage.getItem('LoginedUserID'),
    authStatus: state => state.status,
  }
}