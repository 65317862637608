/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
import Vue from 'vue'
let medicalRecordsApiUrl = root.medicalRecordsApiUrl.link;
let diseasesListApiUrl = root.diseasesListApiUrl.link;
let getMedicalRecordListApiUrl = root.getMedicalRecordListApiUrl.link;
let uploadMulitpleFileOfHrApiUrl = root.uploadMulitpleFileOfHrApiUrl.link;
let uploadMultipleFilesApiUrl = root.uploadMultipleFilesApiUrl.link;
let getMulitpleFileOfHrApiUrl = root.getMulitpleFileOfHrApiUrl.link;

export default {
  state: {
    healthRecordsState:{},
    healthRecordsDataByIdState:{}
  },
  mutations: {
    healthRecordsMutation(state, value) {
      Vue.set(state, 'healthRecordsState', value);
    },
    healthRecordsDataByIdMutation(state, value) {
      Vue.set(state, 'healthRecordsDataByIdState', value);
    }
  },
  actions: {
    // get Medical Records Of Files Data Fun
    getMulitpleFileOfHrMuatation(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(getMulitpleFileOfHrApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Update Multiple File Of Health Record Fun
    updatedMulitpleFileOfHrFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(uploadMultipleFilesApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Upload Multiple File Of Health Record Fun
    uploadMulitpleFileOfHrFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(uploadMulitpleFileOfHrApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // get Medical Records List Data Fun
    getMedicalRecordListFunc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(getMedicalRecordListApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Diseases List Fun
    getDiseasesListDataApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(diseasesListApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Upload Health Records Data Fun
    uploadHelthRecordsDataApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(medicalRecordsApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // get Health Records Data Fun
    getHelthRecordsListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(medicalRecordsApiUrl, payload).then(response => {
            context.commit('healthRecordsMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Update Health Records By ID Data Fun
    updateHealthRecordDataByIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(medicalRecordsApiUrl + payload.data.id + '/', payload.data.data).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Update Health Records By ID Data Fun
    getHelthRecordsDataByIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(medicalRecordsApiUrl + payload.id + '/').then(response => {
              context.commit('healthRecordsDataByIdMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Remove Health Records By ID Data Fun
    removeHelthRecordsDataByIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(medicalRecordsApiUrl + payload.id + '/').then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    }
  },
  getters: {
    healthRecordsGetters : state => state.healthRecordsState,
    healthRecordsDataByIdGetters : state => state.healthRecordsDataByIdState
  }
}