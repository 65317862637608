/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
import Vue from 'vue'
let publicPrescriptionApiUrl = root.publicPrescriptionApiUrl.link;
let memberFiltersForSignUpApiURL = root.memberFiltersForSignUpApiUrl.link;

export default {
  state: {
    publicPrescritionState:{}
  },
  mutations: {
    publicPrescritionMutation(state, value) {
      Vue.set(state, 'publicPrescritionState', value);
    },
  },
  actions: {
    // Public Prescription Data Fun
    getMemberFilterListForSignupFun(context, payload) {
      return new Promise((resolve, reject) => {
        var instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];
        instance.get(memberFiltersForSignUpApiURL)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    // Public Prescription Data Fun
    publicPrescriptionDataApiFun(context, payload) {
      return new Promise((resolve, reject) => {
        var instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];
        instance.get(publicPrescriptionApiUrl + payload.id + '/')
          .then((response) => {
            context.commit('publicPrescritionMutation', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
  },
  getters: {
    publicPrescritionGetters : state => state.publicPrescritionState
  }
}