/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let doctorFiteredUrl = root.consultDoctorsApiUrl.link;
export default {
  state: {
    getOnlineTherapyDoctorsListState:{},
  },
  mutations: {
    getOnlineTherapyDoctorsListMutation(state, value) {
      Vue.set(state, 'getOnlineTherapyDoctorsListState', value);
    },
  },
  actions: {
    // Get Online Therapy Doctors List Action Function
    getOnlineTherapyDoctorsListFun(context, payload) {
        return new Promise((resolve, reject) => {
         axios.get(doctorFiteredUrl + '?filter_type=' + 'psychiatrist').then(response => {
            context.commit('getOnlineTherapyDoctorsListMutation', response.data.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    }
  },
  getters: {
    getOnlineTherapyDoctorsListGetters : state => state.getOnlineTherapyDoctorsListState,
  }
}