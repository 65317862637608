/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
import Vue from 'vue'
let selfUserInfoApiUrl = root.selfUserInfoApiUrl.link;
let patientInfoApiUrl = root.patientInfoApiUrl.link;
let idProofApiUrl = root.idProofApiUrl.link;
let lbilLinkageToLymphomapInfoApiUrl = root.lbilLinkageToLymphomapInfoApiUrl.link;
let userSupportHelpApiURL = root.userSupportHelpApiUrl.link;
let roomCreateIdforGlobalSocketApi = root.roomCreateIdforGlobalSocketApi.link;

export default {
  state: {
    selfUserDetailState: {},
    getLbilLinkageToLymphomapInfoState:{}
  },
  mutations: {
    selfUserDetailMutations(state, value) {
      Vue.set(state, 'selfUserDetailState', value);
    },
    getLbilLinkageToLymphomapInfoMutation(state, value) {
      Vue.set(state, 'getLbilLinkageToLymphomapInfoState', value);
    },
  },
  actions: {
    // room Create Id for Global Socket Function
    roomCreateIdforGlobalSocketMethod(context,payload){
      return new Promise ((resolve, reject) => {
        axios.get(roomCreateIdforGlobalSocketApi).then((res)=>{
          resolve(res);
         }).catch((err)=>{
          reject(err);
         })
      })
    },
    // Get User Support Help Chat List
    getUserSupportHelpChatDetailsByIdFun(context, payload){
      return new Promise((resolve, reject) =>{
        axios.get(userSupportHelpApiURL + payload.id + '/', payload).then((res) => {
          resolve(res);
        }).catch((err)=>{
          reject(err);
        })
      })
    },
    // Get Lbil Linkage Lymphomap Info Action Function
    getLbilLinkageToLymphomapInfoApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(lbilLinkageToLymphomapInfoApiUrl, payload).then(response => {
            context.commit('getLbilLinkageToLymphomapInfoMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Self User Info Action Function
    getSelfUserInfoApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(selfUserInfoApiUrl, payload).then(response => {
            context.commit('selfUserDetailMutations', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    patientInfoUpdateAction(context, payload) {
      return new Promise((resolve, reject) => {
          axios.patch(patientInfoApiUrl, payload).then(response => {
           resolve(response);
          }).catch(err => {
           reject(err);
          })
      })
    },
    uploadIdProofAction(context, payload) {
      return new Promise((resolve, reject) => {
          axios.patch(idProofApiUrl, payload).then(response => {
           resolve(response);
          }).catch(err => {
           reject(err);
          })
      })
    },
  },
  getters: {
    selfUserDetailGetters : state => state.selfUserDetailState,
    getLbilLinkageToLymphomapInfoGetters : state => state.getLbilLinkageToLymphomapInfoState,
  }
}