/*eslint-disable */

import 'bootstrap/dist/css/bootstrap.min.css';
import "vue-tel-input/dist/vue-tel-input.css";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'animate.css';

import App from './app/index';
import VCalendar from 'v-calendar';
import VeeValidate from 'vee-validate';
import Vue from 'vue'
import VueRouter from 'vue-router';
import axios from 'axios';
import interceptor from './services/interceptor';
import router from './route';
import store from './store/store.js';
import timestamp from './services/timestamp';
import vco from "v-click-outside";

Vue.config.productionTip = false
Vue.use(VueRouter, axios);
Vue.use(VeeValidate, {
  validity: true,
  required: true
});
Vue.use(vco);
Vue.use(VCalendar);

// Bus
export const bus = new Vue();

// Authorization Token Set In LocalStorage

const token = localStorage.getItem('token');
if (token != '' && token != null) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('token');
}
store.dispatch('getAuthHeader');

new Vue({
  render: h => h(App),
  router: router,
  store: store,
  interceptor,
  timestamp
}).$mount('#app')
