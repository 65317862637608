/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
import Vue from 'vue'
let postNotificationApiUrl = root.postNotificationApiUrl.link;
let lastMessageReadApiUrl = root.lastMessageReadApiUrl.link;


export default {
  state: {
    getNotificationState:{}
  },
  mutations: {
    getNotificationMutation(state, value) {
      Vue.set(state, 'getNotificationState', value);
    },
  },
  actions: {
    // Last Message Read Fun
    lastMessageReadFunc(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(lastMessageReadApiUrl, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    // get Notification Fun
    getNotificationFunc(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(postNotificationApiUrl)
          .then((response) => {
            context.commit('getNotificationMutation', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    // Post Notification Fun
    postNotificationFunc(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(postNotificationApiUrl, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    }
  },
  getters: {
    getNotificationGetters : state => state.getNotificationState
  }
}