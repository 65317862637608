/*eslint-disable */
import {bus} from '@/main';
import headerBeforeLogin from './before-login-header/index';
import headerAfterLogin from './after-login-header/index';
export default {
  name: 'app_header',
  components: {
    'header-before-login': headerBeforeLogin,
    'header-after-login': headerAfterLogin
  },
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    // handleScroll() {
    //   let header = document.querySelector(".header_sticky");
    //   if (window.scrollY > 500 && !header.className.includes('header_is_sticky')) {
    //     header.classList.add('header_is_sticky'); 
    //   }else if (window.scrollY < 500) {
    //       header.classList.remove('header_is_sticky');
    //   }
    // },
  },
  watch: {
    checkUserLogin() {
      return this.$store.getters.isAuthenticated
    }
  },
  created () {
    // window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    // window.removeEventListener('scroll', this.handleScroll);
  },
}